import React from 'react';
import {useState, useEffect} from 'react';

const Pagination = (props) => {

    const [intervals, setIntervals] = useState(null)
    const [index, setIndex] = useState(0)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => {
        let page_size = props.page_size ? props.page_size : 8
        let interval = []
        let total_pages = Math.ceil(props.pager.count/page_size)

        for (let i = 2; i < total_pages; i++) {
            interval.push(i);
        }
        let interval_slice = []
        for(let i=0; i < total_pages; i += 5)
            interval_slice.push(interval.slice(i, i+5))

        setTotalPages(Math.ceil(props.pager.count/page_size))
        setIntervals(interval_slice)
    }, [props.pager, props.page, props.page_size])
    
    useEffect(() => {
      if (props.page) {
        if (props.page === 1) {
          setIndex(0)
        }
        else if (intervals) {
            intervals.map((interval, index) => {
              if (interval.includes(props.page)) {
                setIndex(index)
              }
          })
        }
      }
    }, [props.page])

    const nextInterval = () => {
        let new_index = index + 1
        if (new_index >= 0)
          setIndex(new_index)
    }

    const previousInterval = () => {
        let new_index = index - 1
        if (new_index <= intervals.length)
          setIndex(new_index)
    }

    let paginationOn = props.pager.count && props.pager.count !== 0 ? true : false

    return (
      <>
        { paginationOn &&
            <nav className="flex items-center justify-between pt-4 mt-6 flex-col md:flex-row" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-400 mb-2 md:mb-0">Registro <span className="font-semibold text-white">{`${((props?.page - 1) * props.page_size) + 1} - ${(props?.page * props.page_size)}`}</span> de <span className="font-semibold text-white">{props?.pager?.count}</span></span>
                <ul className="-space-x-px text-sm h-8 hidden md:inline-flex">
                    {   props.pager?.previous ?
                        <button onClick={() => props.handlePagerChange(props.pager.previous)}>
                            <a className="flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-l-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Anterior</a>
                        </button>
                        :
                        <button disabled={true}>
                            <a disabled={props.pager?.previous == null} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-l-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Anterior</a>
                        </button>
                    }
                    <button onClick={() => props.handlePagerChange(1)}>
                    { props.page === 1 ?            
                        <a className="flex items-center justify-center px-3 h-8 border hover:bg-blue-100 hover:text-blue-700 border-gray-700 bg-gray-700 text-white">1</a>
                        :
                        <a className="flex items-center justify-center px-3 h-8 leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">1</a>
                    }
                    </button>
                    
                    { index !== 0 && 
                        <button onClick={() => previousInterval()}>
                            <a className="flex items-center justify-center px-3 h-8 leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">...</a>
                        </button>
                    }
                    { 
                        intervals && intervals[index] &&
                        intervals[index].map((number) => (
                            <button key={number} onClick={() => props.handlePagerChange(number)}>
                                {props.page === number ?
                                    <a className="flex items-center justify-center px-3 h-8 border hover:bg-blue-100 hover:text-blue-700 border-gray-700 bg-gray-700 text-white">{number}</a>
                                    :
                                    <a className="flex items-center justify-center px-3 h-8 leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">{number}</a>
                                }
                            </button>
                            )
                        )
                    }
                    { intervals && index < (intervals.length - 1) &&       
                        <button onClick={() => nextInterval()}>
                            <a className="flex items-center justify-center px-3 h-8 leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">...</a>
                        </button>  
                    }
                    { 1 < totalPages &&
                        <button onClick={() => props.handlePagerChange(totalPages)}>
                            { props.page === totalPages ?
                                 <a className="flex items-center justify-center px-3 h-8 border hover:bg-blue-100 hover:text-blue-700 border-gray-700 bg-gray-700 text-white">{totalPages}</a>
                                 :
                                 <a className="flex items-center justify-center px-3 h-8 leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">{totalPages}</a>
                            }
                        </button>
                    }
                    {   props.pager?.next ?
                        <button onClick={() => props.handlePagerChange(props.pager.next)}>
                            <a className="flex items-center justify-center px-3 h-8 leading-tight border rounded-r-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Siguiente</a>
                        </button>
                        :
                        <button disabled={true}>
                            <a disabled={props.pager?.previous == null} className="flex items-center justify-center px-3 h-8 leading-tight border rounded-r-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Siguiente</a>
                        </button>
                    }
                </ul>
                <ul className="inline-flex -space-x-px text-sm h-8 md:hidden">
                    {   props.pager?.previous ?
                        <button onClick={() => props.handlePagerChange(props.pager.previous)}>
                            <a className="flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-l-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Anterior</a>
                        </button>
                        :
                        <button disabled={true}>
                            <a disabled={props.pager?.previous == null} className="flex items-center justify-center px-3 h-8 ml-0 leading-tight border rounded-l-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Anterior</a>
                        </button>
                    }
                    <button key='page-mobile'>
                        <a className="flex items-center justify-center px-3 h-8 border hover:bg-blue-100 hover:text-blue-700 border-gray-700 bg-gray-700 text-white">{props?.page}</a>
                    </button>
                    {   props.pager?.next ?
                        <button onClick={() => props.handlePagerChange(props.pager.next)}>
                            <a className="flex items-center justify-center px-3 h-8 leading-tight border rounded-r-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Siguiente</a>
                        </button>
                        :
                        <button disabled={true}>
                            <a disabled={props.pager?.previous == null} className="flex items-center justify-center px-3 h-8 leading-tight border rounded-r-lg bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white">Siguiente</a>
                        </button>
                    }
                </ul>
            </nav>
        }
      </>
    );
}

export default Pagination;
