import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Constants from '../constants';

import ValidationCheck from '../components/ValidationCheck';
import Card from '../components/Card';
import CardTransaction from '../components/CardTransaction';
import Api from '../utils/Api';
import SendTransaction from '../components/SendTransaction';
import ReceiveTransaction from '../components/ReceiveTransaction';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';


const Wallet = (props) => {
    const [balance, setBalance] = useState(null)
    const [transactions, setTransactions] = useState(null)
    const [openSendTransaction, setOpenSendTransaction] = useState(false)
    const [openReceiveTransaction, setOpenReceiveTransaction] = useState(false)
    const user = useSelector((state) => state?.user?.user);

    useEffect(() => {
        Api.fetch(Constants.BACKEND_APIS.AccountsBalances(), 'GET').then(resp => {
           setBalance(resp)
        }).catch(error => {
            console.log('Error balance')
        });

        Api.fetch(Constants.BACKEND_APIS.AccountTransactions(), 'GET').then(resp => {
           setTransactions(resp.data)
        }).catch(error => {
            console.log('Error transactions')
        })
    }, [])

    return (
        <>  
            <ValidationCheck raise={openReceiveTransaction}>
                <ReceiveTransaction user={user} isOpen={openReceiveTransaction} toggleModal={setOpenReceiveTransaction}/>
            </ValidationCheck>
            <ValidationCheck raise={openSendTransaction}>
                <SendTransaction user={user} isOpen={openSendTransaction} toggleModal={setOpenSendTransaction}/>
            </ValidationCheck>
            <div className='grid grid-cols-1 gap-16 mt-8 md:grid-cols-5'>
                <Card className='md:col-span-3' label='Mi Cuenta'>
                    <div className='grid grid-cols-12 m-8'>
                        <div className='flex justify-between items-center flex-col col-span-12 md:col-span-9'>
                            <div className='p-2 text-2xl md:text-1xl'>
                                Saldo Total
                            </div>
                            <ReactPlaceholder type='textRow' color='#1a4564' style={{width: '15em', borderRadius: '10px'}} showLoadingAnimation={true} ready={balance}>
                                <div className='p-2 text-4xl md:text-5xl'> 
                                    $ {balance?.amount}
                                </div>
                            </ReactPlaceholder>
                            <button to="/" className="m-6 py-2 px-8 button-action"  onClick={() => setOpenReceiveTransaction(true)}>DEPOSITAR SALDO</button>
                        </div>
                        <div className='items-center flex-col justify-center pl-4 border-l border-gray-500 col-span-3 hidden lg:flex'>
                            <div>
                                <button className='flex items-center mb-4 pointer button-send-recive' onClick={() => setOpenSendTransaction(true)}>
                                    <i className='bx bxs-right-top-arrow-circle bx-md text-5xl mr-2 cursor-pointer color-yellow-orange'></i>
                                    <div className='text-1xl pointer'>ENVIAR</div>
                                </button>
                                <button className='flex items-center pointer button-send-recive'> 
                                    <i className='bx bxs-down-arrow-circle bx-md text-5xl mr-2 cursor-pointer color-yellow-orange'></i>
                                    <div className='text-1xl'>RECIBIR</div>
                                </button>
                            </div> 
                        </div>
                    </div>
                    <div className='grid grid-cols-2 m-8 mt-4 md:p-4 rounded-lg border card-amount-wallet'>
                        <div className='flex flex-col justify-center items-center col-span-1 '>
                            <div className='mb-1 text-white font-bold text-sm'>
                                Disponible
                            </div>
                            <ReactPlaceholder id='1' key='1221' type='textRow' color='#1a4564' style={{width: '10em', borderRadius: '10px'}} showLoadingAnimation={true} ready={balance}>
                                <div className='text-xl'>                                       
                                    $ {balance?.amount - balance?.blocked_amount}
                                </div>
                            </ReactPlaceholder>
                        </div>
                        <div className='flex flex-col justify-center items-center col-span-1 border-l border-gray-500'>
                            <div className='mb-1 text-white font-bold text-sm'>
                                Bloqueado
                            </div>
                            <ReactPlaceholder id='2' key='1232' type='textRow' color='#1a4564' style={{width: '10em', borderRadius: '10px'}} showLoadingAnimation={true} ready={balance}>
                                <div className='text-xl'>
                                    $ {balance?.blocked_amount}
                                </div>
                            </ReactPlaceholder>
                        </div>
                    </div>
                </Card>
                <Card className='md:col-span-2' label='Últimas Transacciones'>
                    <ReactPlaceholder type='text' color='#1a4564' style={{borderRadius: '10px', marginTop: '2em'}} showLoadingAnimation={true} ready={transactions}>
                        { transactions && transactions.length > 0 ?
                            transactions.slice(0, 5).map(t => (
                                <CardTransaction key={t.id} {...t}/>
                            ))
                            : <div className='text-center mt-3'>No hay transaciones</div>
                        }
                    </ReactPlaceholder>
                    <div className='flex justify-center mt-24 mb-4'>
                        <Link to='/transactions' className='flex text-xs m-2 py-2 px-16 justify-center items-center rounded-full button-outliner'>VER HISTORIAL</Link>
                    </div>
                </Card>
                <div className="backdrop-blur-xl border border-gray-600 bg-white/10  p-4 fixed bottom-0 left-0 right-0 lg:hidden">
                    <div className='flex flex-row justify-around'>
                        <button className='flex text-white items-center' onClick={() => setOpenSendTransaction(true)}>
                            <i className='bx bxs-right-top-arrow-circle text-5xl cursor-pointer color-yellow-orange'></i>
                            <div className='text-1xl pointer'>ENVIAR</div>
                        </button>
                        <button className='flex text-white items-center'> 
                            <i className='bx bxs-down-arrow-circle text-5xl cursor-pointer color-yellow-orange'></i>
                            <div className='text-1xl'>RECIBIR</div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet;