import React from 'react';

const Modal = (props) => {
  return (
   <>
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className={`${props.isOpen ? '' : 'hidden '}fixed flex top-0 left-0 h-full right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full modal-background`}>
        <div className="relative m-auto w-full max-w-lg max-h-full">
          <div className="relative modal-container">
            { !props.noClose &&
                <button onClick={() => props.toggleModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white" data-modal-hide="crypto-modal">
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
            }
              <div className="px-6 py-4 border-b rounded-t border-gray-600 flex justify-center">
                  <h3 className="font-semibold lg:text-xltext-white text-white text-lg">
                      {props.title}
                  </h3>
              </div>

              <div className="pt-6 pl-6 pr-6 pb-4">
                  {props.children}
                  <div className='border-t rounded-t border-gray-600 pt-4 flex justify-center'>
                      <a href="#" className="inline-flex items-center justify-center text-xs font-normal hover:underline text-gray-400">
                          <svg className="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                          </svg>
                          {props.footer}
                      </a>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;