import React, { useEffect, useState } from 'react';
import Api from '../utils/Api';
import { Link } from 'react-router-dom';

import Constants from '../constants';

import Card from '../components/Card';
import { useSelector } from 'react-redux';

const statusToES = {
    'PROCESSING': 'Procesando',
    'COMPLETED': 'Completada',
    'PENDING': 'Pendiente',
    'NO CREATED': 'No Iniciada',
}

const Account = (props) => {
    const user = useSelector((state) => state?.user?.user);
    const [edit, setEdit] = useState(false)
    const [account, setAccount] = useState(null)
    const [validation, setValidation] = useState(null)
    const [validationStatus, setValidationStatus] = useState(false)
   

    useEffect(() => {    
        getAccount()
        getValidationStatus()
        getValidation()
    }, [])

    const getValidationStatus = () => {
        return Api.fetch(Constants.BACKEND_APIS.statusValidation(), 'GET').then(resp => {
            setValidationStatus(resp.status)
        }).catch(error => {
        })
    }

    const getAccount = () => {
        return Api.fetch(Constants.BACKEND_APIS.accounts(user.id), 'GET').then(resp => {
            setAccount(resp)
        }).catch(error => {
            console.log('error get account')
        })
    }

    const getValidation = () => {
        return Api.fetch(Constants.BACKEND_APIS.validations(), 'GET').then(resp => {
            setValidation(resp)
        }).catch(error => {
            console.log('error get account')
        })
    }
    
    function ParseDateTime(date) {
        if (! date) {
            return '-';
        }
        const new_date = new Date(date);
        return (
            new_date.toLocaleDateString() + " " + new_date.toLocaleTimeString()
        );
    }

    function ParseDate(date) {
        if (! date) {
            return '-';
        }
        const new_date = new Date(date);
        return (
            new_date.toLocaleDateString()
        );
    }

    return (
       
        <div className='grid grid-cols-1 md:gap-16 md:mt-8 md:pl-16 md:pr-16 md:grid-cols-12'>
            <Card label='Resumen' className='col-span-6 mb-8 md:mb-0'>              
                <div className='w-9/12 flex flex-col mr-auto ml-auto pb-4 pt-12'>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={account?.uuid} id="id-input" className="block pt-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="id-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">ID</label>
                    </div>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={account?.user?.username} id="username-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="username-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Usuario</label>
                    </div>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={ParseDateTime(account?.date_created)} id="date_created-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="date_created-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fecha de creación</label>
                    </div>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={account?.bank_cvu?.cvu_id} id="cvu-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="cvu-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">CVU</label>
                    </div>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={account?.bank_cvu?.cvu_alias} id="alias-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="alias-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">ALIAS</label>
                    </div>
                    <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                        <input disabled='true' value={account?.bank_cvu?.provider} id="provider-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="provider-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Proveedor</label>
                    </div>
                    <div className='flex flex-col'>
                        <div className="flex items-center mb-4">
                            <input checked={account?.user?.is_active} id="active-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                            <label htmlFor="active-checkbox" className="ml-2 text-xs font-medium text-gray-300">Activa</label>
                        </div>
                        <div className="flex items-center mb-4">
                            <input checked={account?.twofa === 'COMPLETED'} id="twofa-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                            <label htmlFor="twofa-checkbox" className="ml-2 text-xs font-medium text-gray-300">{`2FA (${statusToES[account?.twofa]})`}</label>
                        </div>
                        <div className="flex items-center mb-4">
                            <input checked={validationStatus === 'COMPLETED'} id="validated-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                            <label htmlFor="validated-checkbox" className="ml-2 text-xs font-medium text-gray-300">{`Validación (${statusToES[validationStatus]})`}</label>
                        </div>
                    </div>
                </div>
            </Card>
            <Card label='Validación' className='col-span-6 mb-8 md:mb-0'>
                <div className='w-9/12 flex flex-col mr-auto ml-auto pb-4 pt-12'>
                    { validationStatus && validationStatus === 'COMPLETED'  &&
                        <>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" name='first_name' value={validation?.first_name} id="first_name-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="first_name" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nombre</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.last_name} name='last_name' id="last_name-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="last_name" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Apellido</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled='true' value={ParseDate(validation?.birthday)} id="birthday-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="birthday-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fecha de Nacimiento</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.email} id="email-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="email-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.phone} id="phone-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="phone-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Teléfono</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.address} id="address-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="address-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Direción</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.postal_code} id="postal_code-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="postal_code-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Código Postal</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.city} id="city-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="city-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Ciudad</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.state} id="state-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="state-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Estado / Provincia</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.nationality} id="nationality-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="nationality-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nacionalidad</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.cuit} name='cuit' id="cuit-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="cuit-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">CUIT/CUIL</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.id_number} name='id_number' id="id_number-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="id_number-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">DNI</label>
                            </div>
                            <div className="relative z-0 w-full mb-8 ml-auto mr-auto">
                                <input disabled={true} type="text" value={validation?.gender} id="gender-input" className="block py-2.5 pb-1 px-0 w-full text-sm bg-transparent border-0 border-b-2 disabled:border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 border-blue-400 peer" placeholder=" " required />
                                <label htmlFor="gender-input" className="peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Genero</label>
                            </div>
                        </>
                        
                    }
                    { validationStatus === 'NO CREATED' &&
                        <>
                            <div className='w-full justify-center text-center'>No has completado la validación</div>
                            <Link to='/validation' className="flex w-11/12 ml-auto mr-auto text-xs mt-8 mb-8 py-2 px-16 justify-center items-center rounded-full button-action">Completar Validación</Link>
                        </>
                    }
                    { validationStatus !== 'NO CREATED' && validationStatus !== 'COMPLETED' &&
                        <div className='w-full justify-center text-center'>Validación: {statusToES[validationStatus]}</div>
                    }
                </div>
            </Card>
        </div>
    )
}

export default Account;