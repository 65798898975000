import React, { useEffect, useState } from 'react';

import Api from '../utils/Api';
import Constants from '../constants';
import { ParseDateTime } from '../utils/Parser';
import Pagination from '../components/Pagination';

const statusToColor = {
    'processing': 'color-yellow-orange ',
    'completed': 'color-aqua-green ',
    'pending': 'color-dark-sky-blue ',
    'cancelled': 'color-pink-red ',
}

const headers = [
    'id', 'fecha de creación', 'cvu remitente', 'cvu destinatario', 'monto', 'tipo', 'estado']

const Transactions = (props) => {
    const [transactions, setTransactions] = useState([])
    const [pagination, setPagination] = useState(null)
    const [filters, setFilters] = useState({
        page: 1,
    })
    
    useEffect(() => {
        filter()
    }, [])

    const filter = () => {
        Api.fetch(Constants.BACKEND_APIS.AccountTransactions(), 'GET', filters).then(resp => {
            setTransactions(resp.data)
            setPagination(
            {
                next: resp._pagination.next ? filters.page + 1 : null,
                previous: (filters.page > 1 ? filters.page - 1 : null),
                count: resp._pagination.count
            })
         }).catch(error => {
             console.log('Error balance')
         });
    }

    const handlePagerChange = (page) => {
        const newFilters = filters;
        newFilters['page'] = page;
        setFilters(newFilters)
        filter();
    }
    
    return (
        <>  
            <div className='mt-10 text-3xl text-white font-bold'>
                Historial
            </div>
            <div className='mt-2 text-md text-white'>
                A continuacion te mostramos el historial de todas las operacion realizadas dentro de tu cuenta.
            </div>
            <div className="relative overflow-x-auto mt-10">
                <table className="w-full text-sm text-left whitespace-nowrap text-gray-200 border border-gray-600">
                    <thead className="text-xs text-gray-700 uppercase hidden md:table-header-group">
                        <tr className='border-b border-gray-500 backdrop-blumr-s bg-white/10'>
                            {headers.map( h => {
                                return (
                                    <th key={h} scope="col" className="px-6 py-3 whitespace-nowrap text-gray-200">
                                        {h}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.map(t => {
                                return (
                                    <>
                                        <tr className="border-b border-gray-600 hidden md:table-row">
                                            <th scope="row" className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800">
                                                {t.id}
                                            </th>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800">
                                                <ParseDateTime date={t.date_created}/>
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800">
                                                {t.sender_cvu}
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800">
                                                {t.receiver_cvu}
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800">
                                                {`${t.type === 'DEPOSIT' ? '+ ': '- '} ` + t.amount + ' ARS'}
                                            </td>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-gray-400 uppercase bg-zinc-800">
                                                {t.type}
                                            </td>
                                            <td className={`${statusToColor[t.status]} px-6 py-4 text-xs whitespace-nowrap text-gray-400 bg-zinc-800`}>
                                                {t.status}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-gray-600 md:hidden">
                                            <td className="px-6 py-4 font-medium whitespace-break-spaces text-gray-400">
                                                <div className='uppercase font-bold'>
                                                    {t.type}
                                                </div>
                                                <div className='color-dark-sky-blue text-xs'>
                                                    <ParseDateTime  date={t.date_created}/>
                                                </div>
                                                <div>
                                                    {`${t.type === 'DEPOSIT' ? 'Desde: ' + t.receiver_cvu : 'Hacia: ' + t.sender_cvu}`}
                                                </div>
                                                <div>
                                                    Orden: {t.id}
                                                </div>
                                            </td>    
                                            <td className="px-6 py-4 font-medium whitespace-break-spaces text-gray-400 flex flex-col">
                                                <div className='ml-auto'>{`${t.type === 'deposit' ? '+ ': '- '} ` + t.amount + ' ARS'}</div>
                                                <div className={`${statusToColor[t.status]} ml-auto`}>
                                                    {t.status}
                                                </div>
                                            </td>      
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
                { pagination &&
                    <Pagination key="pagination" page={filters.page} page_size={8}
                        pager={pagination} handlePagerChange={(p) => handlePagerChange(p)}/>
                }
            </div>
        </>
    )
}

export default Transactions;