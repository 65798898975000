import Environment from './environment';

const baseApiUrl = `${Environment.serverUrl}/`

const Constants = {

    CAPTCHA_SITE_KEY : Environment.captchaSiteKey,

    BACKEND_APIS : {
        login: () => `${baseApiUrl}api/authorization/login/`,
        register: () => `${baseApiUrl}api/accounts/register/`,
        enable2FA: (id) => `${baseApiUrl}api/accounts/${id}/enable-2fa/`,
        deactivateAccount: (id) => `${baseApiUrl}api/accounts/${id}/deactivate-account/`,
        confirm2FA: (id) => `${baseApiUrl}api/accounts/${id}/confirm-2fa/`,
        validate2FA: (id) => `${baseApiUrl}api/accounts/${id}/validate-2fa/`,
        accounts: (id) => `${baseApiUrl}api/accounts/${id}/`,
        updateAccount: () => `${baseApiUrl}api/accounts/update-account/`,
        AccountsBalances: () => `${baseApiUrl}api/balances/account-balance/`,
        AccountsCVUs: () => `${baseApiUrl}api/bank-cvu/bank-cvu/`,
        AccountsCreateCVU: () => `${baseApiUrl}api/bank-cvu/create-bank-cvu/`,
        AccountTransactions: () => `${baseApiUrl}api/transactions/account-transactions/`,
        generateReceipt: () => `${baseApiUrl}api/transactions/generate-receipt/`,
        createWithdrawal: () => `${baseApiUrl}api/transactions/create-withdrawal/`,
        validations: () => `${baseApiUrl}api/validations/account-validation/`,
        createValidation: () => `${baseApiUrl}api/validations/create-validation/`,
        statusValidation: () => `${baseApiUrl}api/validations/validation-status/`,
    },

    SUPPORTED_COUNTRIES: ['AR', 'BR', 'MX', 'CL', 'CO', 'PE', 'UY', 'ES'],
    SUPPORTED_COUNTRIES_FLAGS : {
        'AR': {name: 'Argentina', flag : [0x1F1E6, 0x1F1F7]},
        'BR': {name: 'Brasil', flag : [0x1F1E7, 0x1F1F7]},
        'MX': {name: 'México', flag : [0x1F1F2, 0x1F1FD]},
        'CL': {name: 'Chile', flag : [0x1F1E8, 0x1F1F1]},
        'CO': {name: 'Colombia', flag : [0x1F1E8, 0x1F1F4]},
        'PE': {name: 'Perú', flag : [0x1F1F5, 0x1F1EA]},
        'UY': {name: 'Uruguay', flag : [0x1F1FA, 0x1F1FE]},
        'ES': {name: 'España', flag : [0x1F1EA, 0x1F1F8]},
    },
}

export default Constants;