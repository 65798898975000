import React from 'react';
import Api from '../utils/Api';
import Constants from '../constants';
import { ParseDateTime } from '../utils/Parser';

const CardTransaction = (props) => {

    const statusToColor = {
        'PROCESSING': 'color-yellow-orange ',
        'COMPLETED': 'color-aqua-green ',
        'PENDING': 'color-dark-sky-blue ',
        'CANCELLED': 'color-pink-red ',
    }

    const statusToES = {
        'PROCESSING': 'Procesando',
        'COMPLETED': 'Completada ',
        'PENDING': 'Pendiente ',
        'CANCELLED': 'Cancelada ',
    }

    const typeToES = {
        'DEPOSIT': 'DEPOSITO',
        'WITHDRAWAL': 'RETIRO ',
    }

    const generateReceipt = () => {
        Api.fetchDownload(Constants.BACKEND_APIS.generateReceipt(), 'POST', `receipt_${props.uuid}`, {'txn': props.uuid})
    }

    return (
        <>
            <div className='grid grid-cols-12 mt-4 border-b border-gray-900 pr-8 py-2'>
                <div className='flex col-span-2 justify-center items-center'>
                    {
                        props.type === 'DEPOSIT' 
                        ? <i className='bx bxs-down-arrow-alt bx-md text-3xl color-aqua-green'></i>
                        : <i className='bx bxs-up-arrow-alt bx-md text-3xl rotate-45 color-pink-red'></i>
                    }
                </div>
                <div className='grid col-span-10'>
                    <div className='grid grid-cols-2'>
                        <div className='text-sm font-bold'>{typeToES[props?.type]}</div>
                        <div className='text-right text-md'>$ {props.amount}</div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='grid grid-cols-1'>
                            <div className='color-dark-sky-blue text-xs font-bold'><ParseDateTime date={props.date_created}/></div>
                            <div className='text-xs'>Orden: {props.uuid}</div>
                        </div>
                        <div className='grid grid-cols-1'>
                            <div className={`${statusToColor[props.status]} items-right text-right text-xs font-bold uppercase`}>{statusToES[props.status]}</div>
                            <button onClick={generateReceipt} className='flex text-xs m-1 py-2 justify-end items-center rounded-full'><i className='bx bxs-download bx-xs mr-1 color-dark-sky-blue'></i>Descargar Comprobante</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardTransaction;