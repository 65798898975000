import React, { useEffect, useState } from 'react';
import Constants from '../constants';
import Api from '../utils/Api';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../redux/userSlice';
import HomeImg from '../images/home.svg';
import Swal from 'sweetalert2'

const Home = () => {
    const user = useSelector((state) => state?.user?.user);
    const dispatch = useDispatch();
    const [account, setAccount] = useState(null)

    useEffect(() => {    
        if (user !== null) {
            getAccount()
        }
    }, [])

    const getAccount = () => {
        return Api.fetch(Constants.BACKEND_APIS.accounts(user.id), 'GET').then(resp => {
            setAccount(resp)
        }).catch(error => {
            console.log('error get account')
        })
    }

    const deactivateAccount = () => {
        getAccount()
        Swal.fire({
            background: 'rgb(38, 43, 51)',
            icon: null,
            text: 'Estas seguro que deseas desactivar tu cuenta?',
            confirmButtonText: 'Desactivar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            showCloseButton: true,
            customClass: {
                confirmButton: 'flex py-2 p-8 mb-8 rounded-full confirm-button',
                cancelButton: 'flex ml-4 mr-4 py-2 p-8 mb-8 rounded-full border border-gray-600 ',
                content: 'text-white'
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                return Api.fetch(Constants.BACKEND_APIS.deactivateAccount(account.id), 'POST').then(res => {
                    Swal.fire({
                        background: 'rgb(38, 43, 51)',
                        icon: 'success',
                        text: 'Cuenta desactivada!',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                            content: 'text-white'
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (dispatch) {
                            dispatch(logout());
                        }
                        window.location.replace('/login')
                    })
                }).catch(error => {
                    console.log('error')
                    window.location.replace('/login')
                })
            }
        })
    }

    return (
        <> 
            <div className='grid md:grid-cols-2 md:mt-16 mt-4'>
                <div className='col-span-1 md:p-8 md:ml-4 flex flex-col justify-center items-center text-center'>
                    <div data-aos="fade-right" data-aos-duration="1000" className='text-white text-3xl mb-4 md:text-5xl font-title uppercase'>Recibir y enviar dinero nunca fue tan fácil.</div>
                    <div data-aos="fade-down" data-aos-duration="1500" className='color-dark-sky-blue text-xs md:text-2xl mb-4 font-subtitle uppercase'>Con Digipayments podés realizar y recibir transferencias al instante.</div>
                </div>
                <div className='col-span-1 md:mr-8'>
                    <img data-aos="fade-left" data-aos-duration="1000" src={HomeImg} className='w-full'/>
                </div>
            </div>
            <div className='text-white text-xs mt-8 md:p-16 ml-4 md:text-3xl text-center font-subtitle uppercase'>Manejá tu dinero como quieras en una sóla plataforma</div>
            <div className='grid grid-rows-3 gap-y-8 gap-x-16 mt-8 mx-4 md:mx-0 md:mt-0 lg:px-18 md:grid-cols-3 md:grid-rows-1'>
                <div data-aos="zoom-out-up"  data-aos-duration="500" className='backdrop-blur-sm bg-white/5 top-0 bottom-0 w-full h-96 border border-gray-600 rounded-lg'>
                    <div className='w-full h-full flex flex-col'>
                        <div className='text-white font-bold w-11/12 h-1/6 mt-8 background-yellow border-t-2 border-b-2 border-r-2 rounded-r-full text-2xl flex text-center items-center justify-center'>
                            Enviá a quién quieras
                        </div>   
                        <div className='p-12 text-white text-xl'>
                        Podés transferir a cualquier CBU o CVU, ya sea tuyo o de terceros! 
                        </div>
                    </div> 
                </div>
                <div data-aos="zoom-out-up" data-aos-duration="1500" className='backdrop-blur-sm bg-white/5 top-0 bottom-0 w-full h-96 border border-gray-600 rounded-lg shadow-lg'>
                    <div className='w-full h-full flex flex-col'>
                        <div className='text-white font-bold w-11/12 h-1/6 mt-8 background-blue border-t-2 border-b-2 border-r-2 rounded-r-full text-2xl flex text-center items-center justify-center'>
                            Recibí dinero al instante
                        </div>   
                        <div className='p-12 text-white text-xl'>
                            Podes recibir dinero en tu cuenta desde cualquier CBU o CVU
                        </div>
                    </div> 
                </div>
                <div data-aos="zoom-out-up" data-aos-duration="2000" className='backdrop-blur-sm bg-white/5 top-0 bottom-0 w-full h-96 border border-gray-600 rounded-lg shadow-lg'>
                    <div className='w-full h-full flex flex-col'>
                        <div className='text-white font-bold w-11/12 h-1/6 mt-8 background-gray border-t-2 border-b-2 border-r-2 rounded-r-full text-2xl flex text-center items-center justify-center'>
                            Fácil de usar y siempre disponible
                        </div>   
                        <div className='p-12 text-white text-xl'>
                            Mové tu dinero cuando y donde quieras. 
                        </div>
                    </div> 
                </div>
            </div>
            { user !== null  ?
                <div className='flex w-full justify-center items-center mt-28 border-t-2 border-b-2 border-gray-600 p-8'>
                    <Link onClick={deactivateAccount} className="py-2 px-16 rounded-full button-action text-1xl font-bold">Botón de arrepentimiento...</Link>
                </div>
                :
                <div className='flex w-full justify-center items-center mt-28 border-t-2 border-b-2 border-gray-600 p-8'>
                    <Link to="/register" className="py-2 px-16 rounded-full button-action text-1xl font-bold">Crear Cuenta...</Link>
                </div>
            }
           
        </>
    )
}

export default Home;