import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const Profile = (props) => {
    const location = useLocation();

    return (
        <>
        <div className='grid grid-cols-1 md:grid-cols-12 mt-4'>
            <div className="col-span-2 border-r border-gray-200 dark:border-gray-700 mt-4 hidden md:block">
                <ul className="flex flex-wrap flex-col text-sm font-medium text-center text-gray-500 dark:text-gray-400 justify-center">
                    <li className="">
                        <Link to="" className={`inline-flex items-center justify-center p-4 group ${location.pathname === '/account' ? 'rounded-t-lg active text-blue-500 border-blue-500' : ' text-gray-400  rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`} aria-current="page">
                            <i className='bx bxs-user-circle bx-xs mr-1'></i>
                            Cuenta
                        </Link>
                    </li>
                    {/* <li className="">
                        <Link to="" className={`inline-flex items-center justify-center p-4 group ${location.pathname === '/account/security' ? 'rounded-t-lg active text-blue-500 border-blue-500' : ' text-gray-400  rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`} aria-current="page">
                            <i className='bx bx-lock bx-xs mr-1'></i>
                            Seguridad
                        </Link>
                    </li> */}
                    <li className="">
                        <Link to="transactions" className={`inline-flex items-center justify-center p-4 group ${location.pathname === '/account/transactions' ? 'rounded-t-lg active text-blue-500 border-blue-500' : ' text-gray-400  rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`} aria-current="page">
                            <i className='bx bx-transfer bx-xs mr-1'></i>
                            Transacciones
                        </Link>
                    </li>
                    {/* <li className="">
                        <Link to="limits" className={`inline-flex items-center justify-center p-4 group ${location.pathname === '/account/limits' ? 'rounded-t-lg active text-blue-500 border-blue-500' : ' text-gray-400  rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`} aria-current="page">
                            <i className='bx bx-slider bx-xs mr-1'></i>
                            Limites
                        </Link>
                    </li> */}
                </ul>
            </div>
            <div className='col-span-1 md:pl-12 md:col-span-10'>
                <Outlet />
            </div>
        </div>
        
        </>
    )
}

export default Profile;