import React from 'react';
import { Navigate } from 'react-router-dom';
import auth from '../auth';
import { useDispatch } from 'react-redux';
import { login } from '../redux/userSlice';

export const ProtectedRoute = (props) => {
    const dispatch = useDispatch();
    const isLoggedIn = auth().isLoggedIn()

    if (!isLoggedIn) {
        dispatch(login(null))
        return <Navigate to='/login'/>
    }
    return props.children 
}