function ParseDate(props) {
    if (! props.date) {
      return '-';
    }
    return (
        (new Date(props.date)).toLocaleDateString()
    );
  }
  
  function ParseDateTime(props) {
    if (! props.date) {
        return '-';
    }
    const date = new Date(props.date);
    return (
      date.toLocaleDateString() + " " + date.toLocaleTimeString()
    );
  }
  
  function ParseTimestamp(props) {
    return (
        (new Date(props.timestamp*1000)).toLocaleDateString()
    );
  }
  
  function ParseIdNumber(props) {
      if (props.id_number) {
            if (props.country === 'BR') {
              return props.id_number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"$1.$2.$3-$4");
            }
            if (props.country === 'AR') {
              return props.id_number.replace(/(\d{2})(\d{3})(\d{3})/g,"$1.$2.$3");
            }
            return props.id_number;
      }
      return '-'
  }
  
  function ParseList(props) {
      let parsedList = props.listToParse ? props.listToParse.join(', ') : null
      return parsedList ? parsedList : null
  }
  
  function ParseDecimalZeros(value) {
      const [integerPart, fractionalPart] = value.split('.');
  
      if (!fractionalPart) {
          return value
      }
  
      if (fractionalPart.match(/^0+$/)) {
          return `${integerPart}.00`;
      }
      // If the fractional part has trailing zeros, remove them
      if (fractionalPart.match(/0+$/)) {
          return `${integerPart}.${fractionalPart.replace(/0+$/, '')}`;
      }
      return value
  }
  
  export {
      ParseDate,
        ParseDateTime,
      ParseTimestamp,
      ParseIdNumber,
      ParseList,
      ParseDecimalZeros
  }