import React from 'react';

const Footer = () => {
    return (
        <div className='text-white flex flex-col mt-96 w-full dark-sky-blue items-center p-4 justify-center md:h-48 md:p-0'>
            <div className='text-center items-center flex flex-col text-xs ml-auto mr-auto mb-2 md:flex-row'>Digipayments SAU es un Proveedor de Servicios de Pago que ofrece Cuentas de Pago. No está autorizada por el BCRA para operar como Entidad Financiera.</div>
            <div className='text-center items-center flex flex-col text-xs ml-auto mr-auto mb-8 md:flex-row'>Los fondos depositados en cuentas de pago no constituyen depósitos en una entidad financiera, ni cuentan con ninguna de las garantías que tales depósitos puedan gozar de acuerdo con la legislación y reglamentación aplicables en materia de depósitos en entidades financieras.</div>
            <div className='text-center items-center flex flex-col text-xs ml-auto mr-auto mb-2 md:flex-row font-bold'>Responsables designados ante el BCRA del servicio de atención al usuario de servicios financieros:</div>
            <div className='grid grid-cols-4 gap-12 text-center items-center mb-8'>
                <div className='text-xs'>Titular: German Canale (german.canale@ripio.com)   </div>
                <div className='text-xs'>Suplente: Martina Bustos (martina.bustos@ripio.com)   </div>
                <div className='text-xs'>Domicilio Laboral: 11 de Septiembre de 1888, número 858, CABA   </div>
                <div className='text-xs'>Teléfono: 0800-220-0720  </div>
            </div>
            <div className='grid grid-cols-3 gap-12 text-center items-center'>
                <div className='text-xs'>2023 - 2024© - Todos los derechos reservados   </div>
                <div className='text-xs'><a target='_blank' href='/terms-and-conditions' className='font-bold border-b border-white ml-1'>Contratos de adhesión - Ley N° 24.240 de Defensa del Consumidor</a></div>
                <div className='text-xs'><a target='_blank' href="/privacy-policy" className='font-bold border-b border-white ml-1'>Políticas de privacidad</a></div>
            </div>
        </div>
    )
}
 
export default Footer;