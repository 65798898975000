import React, { useEffect, useState } from 'react';
import Constants from '../constants';
import Api from '../utils/Api';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

const ValidationCheck = (props) => {
    const navigate = useNavigate()
    const [render, setRender] = useState(false)

    useEffect(() => {
        checkValidation()
    }, [props.raise])

    const checkValidation = () => {
        return Api.fetch(Constants.BACKEND_APIS.statusValidation(), 'GET').then(resp => {
            if (!['PENDING', 'PROCESSING', 'COMPLETED'].includes(resp.status)) {
                setRender(false)
                raiseAlert()
                return
            }
            else if (['PENDING', 'PROCESSING'].includes(resp.status)) {
                raiseAlerWait()
            }
            else {
                setRender(true)
            }
        }).catch(error => {
            console.log('error check validation')
        })
    }

    const raiseAlert = () => {
        Swal.fire({
            background: 'rgb(38, 43, 51)',
            title: 'Validación!',
            icon: 'warning',
            text: 'No has realizado la validación de tu cuenta.',
            confirmButtonText: 'Validar',
            showCloseButton: true,
            customClass: {
                confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                content: 'text-white'
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/validation')
            }
        })
    }

    const raiseAlerWait = () => {
        Swal.fire({
            background: 'rgb(38, 43, 51)',
            title: 'Validación!',
            icon: 'info',
            text: 'Tu validacion se encuentra en proceso.',
            confirmButtonText: 'OK',
            showCloseButton: true,
            customClass: {
                confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                content: 'text-white'
            },
            buttonsStyling: false,
        })
    }

    return (
        <>
            { render && !props.noRender &&
                props.children
            }
        </>
    )
}

export default ValidationCheck;