import React, { useEffect } from 'react';
import { useState } from 'react';
import Api from '../utils/Api';
import Constants from '../constants';

import Background from '../components/Background';
import Card from '../components/Card';
import Process2FA from '../process/Process2FA';


const PROCESS_STATES = [
    'REGISTER',
    'VALIDATE-2FA',
    'COMPLETED'
]

const Login = (props) => {

    const [error, setError] = useState(null)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [account, setAccount] = useState(null)
    const [processState, setProcessState] = useState(PROCESS_STATES[0])


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 13) {
                const button = document.getElementById("loginButton");
                if (button) {
                    button.click(); 
                }
            }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };

      }, []); 

    const loginOn = () => {
        
        Api.fetch(Constants.BACKEND_APIS.login(), 'POST', {'username': email, 'password': password}).then(resp => {
            setAccount(resp)
            setProcessState(PROCESS_STATES[1])
        }).catch(error => {     
            error.json().then(e => {
                setError(e[0])
            })
        });
    }

    return (
        <>
            <Background/>
            <Process2FA 
                account={account}
                processState={processState}
                setProcessState={setProcessState}
                redirectURL={'/'}
                noReturn={true}
                dispatch
            />
            <Card className='flex flex-col justify-center items-center w-11/12 md:w-4/12 2xl:w-3/12 md:mt-28 mt-16'>        
                {   processState === PROCESS_STATES[0] &&
                    <>
                        <a href="/" className="flex items-center justify-center mt-6 mb-4">
                            <img src="https://flowbite.com/docs/images/logo.svg" className="h-12 mr-3" alt="Flowbite Logo" />
                        </a>
                        <div className='text-3xl items-center text-center mt-4'>Iniciar Sesión</div>
                        <div className="mb-6 mt-6 ml-auto mr-auto md:w-3/4 ">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Correo</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)}  type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required />
                        </div>
                        <div className="mb-6 ml-auto mr-auto md:w-3/4">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Contraseña</label>
                            <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="•••••••••" required />
                        </div>
                        { error &&
                            <div className='flex text-red-600 text-sm justify-center'>{error}</div>
                        }
                        <button id='loginButton' className="flex w-3/4 ml-auto mr-auto text-base mt-16 mb-8 py-3 px-4 justify-center items-center rounded-full button-action" onClick={loginOn}>Ingresar</button>
                    </>
                }
            </Card>
        </>
        
    )
}

export default Login;