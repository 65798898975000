import React, { useEffect, useState } from 'react';
import Constants from '../constants';
import Api from '../utils/Api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import Background from '../components/Background';
import Datepicker from "react-tailwindcss-datepicker"; 


const Validation = () => {
    const navigate = useNavigate()
    
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        gender: '',
        email: '',
        confirm_email: '',
        birthday: new Date(),
        cuit: '',
        id_number: '',
        phone: '',
        address: '',
        postal_code: '',
        city: '',
        state: '',
        date_created: '',

        photo_front_document: '',
        photo_back_document: '',
    })

    useEffect(() => {
        checkValidation()
    }, [])

    const checkValidation = () => {
        return Api.fetch(Constants.BACKEND_APIS.statusValidation(), 'GET').then(resp => {
            if (['PENDING', 'PROCESSING', 'COMPLETED'].includes(resp.status)) {
                navigate('/')
            }
        }).catch(error => {
        })
    }

    const createValidation = () => {
        const formData = new FormData();

        for (const key in data) {
            if (key === 'birthday') {
                formData.append(key, data[key].startDate);
            }
            else{
                formData.append(key, data[key]);
            }
        }
        return Api.fetchUpload(Constants.BACKEND_APIS.createValidation(), 'POST', formData).then(resp => {
            Swal.fire({
                background: 'rgb(38, 43, 51)',
                icon: 'success',
                text: 'Validacion Completada!',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                    content: 'text-white'
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/account')
                }
            })
        }).catch(error => {
            console.log('error register')
        })
    }

    const handleFileChangeFront = (e) => {
        const file = e.target.files[0];
        const newData = {...data};
        newData['photo_front_document'] = file;
        setData(newData);
      }

    const handleFileChangeBack = (e) => {
        const file = e.target.files[0];
        const newData = {...data};
        newData['photo_back_document'] = file;
        setData(newData);
    }

    const handleValueDatePicker = (newValue) => {
        const newData = {...data};
        newData['birthday'] = newValue;
        setData(newData);
    } 

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value || '';
        const name = target.name;
        const newData = {...data};
        newData[name] = value;
        setData(newData);
    }
    return (
        <>  
        <Background/>
        <div className='grid grid-cols-1 lg:grid-cols-12 h-screen w-full'>
            <div className='grid-rows-5 h-full charcoal-grey p-8 hidden lg:grid lg:col-span-2'>
                <div className='text-white text-center text-1xl mt-4 row-1 font-title'>DIGIPAYMENTS</div>
                <div className='row-2'></div>
                <div className='row-2'></div>
                <div className='row-2'></div>
                <div className='row-2'></div>
                <div className='row-2 text-1xl text-white'>Realiza transferencias a cualquier CBU o CVU de tus amigos.</div>
                {/* <div className='color-yellow-orange text-sm row-1'>Pagos instantáneos y sin comisiones dentro de Digipayments.</div> */}
            </div>
            <div className='col-span-2 flex-col m-5 items-center hidden lg:flex'></div>
            <div className='col-span-3 flex-col m-5 flex items-center mt-16 lg:mt-16'>
                <div className='text-white text-3xl mb-8'>Completa la Validación</div>
                <div className='text-white text-sm mb-2'>Con tan sólo algunos datos puedes cambiar el futuro de tus finanzas.</div> 

                <div className="mb-6 w-full ml-auto mr-auto">
                    <input value={data?.first_name} onChange={handleChange} name="first_name" type="text" id="first_name" className="col-span-1 border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Nombres" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.last_name} name="last_name" type="text" id="last_name" className="col-span-1 border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Apellidos" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.email} name="email" type="text" id="email" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Correo Electronico" required />
                </div>

                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.confirm_email} name="confirm_email" type="text" id="confirm_email" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Repite tu correo" required />
                </div>

                <div className="mb-6 w-full ml-auto mr-auto">
                    <Datepicker useRange={false} asSingle={true} onChange={handleValueDatePicker} value={data?.birthday} name="birthday" id="birthday" inputClassName="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder='Fecha de Nacimiento'  />
                </div>

                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.address} name="address" type="text" id="address" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Direccion" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.city} name="city" type="text" id="city" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Cuidad" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.state} name="state" type="text" id="state" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Estado/Provincia" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.postal_code} name="postal_code" type="text" id="postal_code" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Codigo Postal" required />
                </div>

                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.phone} name="phone" type="text" id="phone" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Numero de Telefono" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <select value={data?.gender} name="gender" id="gender" onChange={handleChange} className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                        <option selected>Genero</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                        <option value="B">No Binario</option>
                    </select>
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.id_number} name="id_number" type="text" id="id_number" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Documento" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <input onChange={handleChange} value={data?.cuit} name="cuit" type="text" id="cuit" className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="CUIT/CUIL" required />
                </div>
                <div className="mb-6 w-full ml-auto mr-auto">
                    <label className="block mb-2 text-sm font-medium text-white" htmlFor="file_input_photo_front_document">Documento: Frente</label>
                    <input onChange={handleFileChangeFront} name="photo_front_document" id="photo_front_document" className="block w-full text-xs border rounded-lg cursor-pointer text-gray-400 focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400" type="file"></input>
                </div>

                <div className="mb-6 w-full ml-auto mr-auto">
                    <label className="block mb-2 text-sm font-medium text-white" htmlFor="file_input_photo_back_document">Documento: Dorso</label>
                    <input onChange={handleFileChangeBack} name="photo_back_document" id="photo_back_document" className="block w-full text-xs border rounded-lg cursor-pointer text-gray-400 focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400" type="file"></input>
                </div>
                <button onClick={createValidation} className="cursor-pointer flex w-11/12 ml-auto mr-auto text-base mt-8 mb-8 py-2 px-16 justify-center items-center rounded-full button-action">VALIDAR</button>
            </div>
        </div>
    </>
    )
}

export default Validation;