import auth from '../auth';
import { getCookie } from './Cookies';

function processDownload(blob, fileName) {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  const href = URL.createObjectURL(blob);
  link.href = href;
  link.setAttribute('target', '_blank');
  link.click();
  URL.revokeObjectURL(href);
}

const Api = {

  handleErrors: function(response) {
    if (response.ok) {
      return response;
    }
    if (response.status === 401) {
      Api.goLogout()
    }
    if (response.status === 403) {
      Api.goLogout()
    }
    throw response;
  },

  handleErrorsNo403: function(response) {
    if (response.ok) {
      return response;
    }
    if (response.status === 401) {
      Api.goLogout()
    }
    throw response;
  },

  getUrlWithParams: function(path, params = {}) {
    let url = new URL(path)
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined && params[key] !== '') {
        url.searchParams.append(key, params[key]);
      }
    });
    return url;
  },

  performFetch: (headers, url, method = 'GET', data = null, options = {}, download = false, fileName = "") => {
    options = {
      timeout: 30000,
      abortController: new AbortController(),
      ...options,
    }

    const info = Object.assign({},
        {
          method,
          headers : {
            ...headers,
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          signal: options.abortController.signal,
        },
        (data && method !== 'GET') ? {'body': JSON.stringify(data)} : null,
        {'credentials': 'include'}
    );

    url = (data && method === 'GET') ? Api.getUrlWithParams(url, data) : url;

    const _timeout = setTimeout(() => {
      options.abortController.abort();
    }, options.timeout);

    return fetch(url, info)
            .then(resp => {
              clearTimeout(_timeout);
              return resp;
            })
            .catch(error => {
              clearTimeout(_timeout);
              return error;
            })
            .then(Api.handleErrors)
            .then(resp => {
              if (resp.status === 204) {
                return null;
              }
              return resp.json()
            })
  },

  performFetchDownload: (headers, url, method = 'GET', data = null, options = {}, download = false, fileName = "") => {
    options = {
      timeout: 30000,
      abortController: new AbortController(),
      ...options,
    }

    const info = Object.assign({},
        {
          method,
          headers : {
            ...headers,
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          signal: options.abortController.signal,
        },
        (data && method !== 'GET') ? {'body': JSON.stringify(data)} : null,
        {'credentials': 'include'}
    );

    url = (data && method === 'GET') ? Api.getUrlWithParams(url, data) : url;

    const _timeout = setTimeout(() => {
      options.abortController.abort();
    }, options.timeout);

    return fetch(url, info)
            .then(resp => {
              clearTimeout(_timeout);
              return resp;
            })
            .catch(error => {
              clearTimeout(_timeout);
              return error;
            })
            .then(Api.handleErrors)
            .then(resp => {
              if (resp.status === 204) {
                return null;
              }
              try {
                if (download) {
                  resp.blob().then((blob) => {
                    processDownload(blob, fileName);
                  });
                } else {
                  return resp.json();
                }
              } catch (e) {
                console.error(e);
                return e;
              }
            })
  },

  performFetchUpload: (headers, url, method = 'POST', form, options = {}) => {

    options = {
      timeout: 30000,
      abortController: new AbortController(),
      ...options,
    }
    
    const info = Object.assign({},
        {
          method,
          headers : {
            ...headers,
            // 'Content-Type': 'multipart/form-data',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          body: form
        },
        {'credentials': 'include'}
    );

    const _timeout = setTimeout(() => {
      options.abortController.abort();
    }, options.timeout);

    return fetch(url, info)
            .then(resp => {
              clearTimeout(_timeout);
              return resp;
            })
            .catch(error => {
              clearTimeout(_timeout);
              return error;
            })
            .then(Api.handleErrors)
            .then(resp => resp.json())
  },

  fetch: function(url, method = 'GET', data = null, options = {}) {
    return Api.performFetch({}, url, method, data, options);
  },

  fetchDownload: function(url, method = 'GET', fileName = "", data = null,  options = {}) {
    return Api.performFetchDownload({}, url, method, data, options, true, fileName);
  },

  fetchUpload: function(url, method = 'POST', form, options = {}) {
    return Api.performFetchUpload({}, url, method, form, options);
  },

  logout: function() {
    auth().goLogout().then(() => {
      auth().goLogin();
    });    
  }
}

export default Api