import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import auth from '../auth';
import User from '../images/user.png'

const Navbar = () => {
    const user = useSelector((state) => state?.user?.user);
    const [openMenu, setOpenMenu] = useState(false)


    const logout = () => {
        auth().goLogout()
    }

    return (
        <>  
            {  openMenu &&
             <div className='absolute top-0 right-0 bg-transparent w-full h-full z-20' onClick={() => setOpenMenu(false)}></div>
            }
            <nav className="backdrop-blur-sm bg-white/10 w-full h-18 z-30">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="/" className="flex items-center">
                        <img src="https://flowbite.com/docs/images/logo.svg" className="h-7 mr-1 md:h-8 md:mr-3" alt="Flowbite Logo" />
                        <span className="self-center md:text-2xl font-semibold whitespace-nowrap text-white">Digipayments</span>
                    </a>
                    <div className="flex items-center md:order-2">
                        { user === null 
                            ?
                                <> 
                                    <Link to="/login" className="text-white hover:text-gray-300 px-3 text-xs md:text-base">Iniciar Sesión</Link>
                                    <Link to="/register" className="ml-3 py-2 px-2 md:px-8 rounded-full button-action text-xs md:text-base">Crear Cuenta</Link>
                                </>    
                            :
                                <div className='flex relative'>    
                                    <button type="button" onClick={() => setOpenMenu(!openMenu)} className="flex mr-3 ml-4 text-xs md:text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 z-50" id="user-menu-button" aria-expanded="false">
                                        <span className="sr-only text-xs md:text-sm">Open user menu</span>
                                        <img className="w-9 h-9 rounded-full" src={User} alt="user photo"/>                                    
                                    </button>
                                    <div className={`${openMenu ? 'md:inline-block ': 'hidden '} hidden z-50 absolute right-0 my-12 text-base list-none card-color divide-y divide-gray-100 rounded-lg shadow border border-gray-600`}>
                                        <div className="px-4 py-3">
                                        <span className="block text-sm text-white">{`${user?.user?.first_name} ${user?.user?.last_name}`}</span>
                                        <span className="block text-sm text-gray-400 truncate">{user?.user?.username}</span>
                                        </div>
                                        <ul className="py-2" aria-labelledby="user-menu-button">
                                            <li>
                                                <a href="/account" className="block px-4 py-2 text-sm text-white hover:bg-gray-600">Perfil</a>
                                            </li>
                                            <li>
                                                <a onClick={logout} className="block px-4 text-sm py-2 text-white hover:bg-gray-600 cursor-pointer">Cerrar Sesion</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button type="button" onClick={() => setOpenMenu(!openMenu)} className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                                        <span className="sr-only">Open main menu</span>
                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </button> 
                                </div>
                        }
                    </div>
                    <div className={`${openMenu ? '': 'hidden '} z-50 items-center justify-between ml-auto mr-4 w-full md:flex md:w-auto md:order-1`} id="navbar-user">
                        <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
                            <li>
                                <a href="/" className="block py-2 pl-3 pr-4  rounded md:p-0 text-white ">Inicio</a>
                            </li>
                            { user !== null &&
                                <>
                                <   li>
                                        <a href="/wallet" className="block py-2 pl-3 pr-4  rounded md:p-0 text-white ">Wallet</a>
                                    </li>
                                    <li>
                                        <a href="/transactions" className="block py-2 pl-3 pr-4  rounded md:p-0 text-white ">Historial</a>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}
export default Navbar;