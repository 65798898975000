import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';

import AppContainer from './components/AppContainer';
import Navbar from './components/Navbar';
import Background from './components/Background';
import Footer from './components/Footer';
import Login from './pages/Login';
import Home from './pages/Home';
import Wallet from './pages/Wallet';
import Register from './pages/Register';

import './styles/admin.css'
import { ProtectedRoute } from './utils/ProtectedRoutes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; 
import { store, persistor } from './redux/store';
import Transactions from './pages/Transactions';
import Profile from './pages/Profile';
import Account from './pages/Account';
import Validation from './pages/Validation';
import TermAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';


function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
              <Routes>
                  <Route path='/login' element={<Login/>} />           
                  <Route path='/register' element={<Register/>} /> 
                  <Route path='/terms-and-conditions' element={<TermAndConditions/>} /> 
                  <Route path='/privacy-policy' element={<PrivacyPolicy/>} />   
                  <Route path='/validation' element={<ProtectedRoute><Validation/></ProtectedRoute>} />  
                  <Route path='/' element={          
                      <>
                        <Background/>
                        <Navbar/> 
                        <AppContainer>
                          <Outlet/>
                        </AppContainer>
                        <Footer/>
                      </>}>
                      <Route path='/' element={<Home/>} />
                      <Route path='/wallet' element={<ProtectedRoute><Wallet/></ProtectedRoute>} /> 
                      <Route path='/transactions' element={<ProtectedRoute><Transactions/></ProtectedRoute>} /> 
                      <Route path='/account' element={<ProtectedRoute><Profile/></ProtectedRoute>}> 
                        <Route path='' element={<ProtectedRoute><Account/></ProtectedRoute>}/>
                        <Route path='transactions' element={<ProtectedRoute><Transactions/></ProtectedRoute>}/>
                      </Route> 
                      <Route path="*" element={<Navigate to="/" />} />
                  </Route>
              </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;