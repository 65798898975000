import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   user: null,
// };

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     login: (state, action) => {
//       state.user = action.payload; 
//     },
//     logout: (state) => {
//       state.user = null; 
//     },
//   },
// });

// export const { login, logout } = userSlice.actions;
// export default userSlice.reducer;


const initialState = {
  user: null, // Aquí almacenaremos los datos del usuario
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state, action) => {
      state.user = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;