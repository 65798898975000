import { getCookie } from './utils/Cookies';
import Environment from './environment';


function Auth() {

    const isLoggedIn = () => {
      const sessionId = getCookie('sessionid_status'); 
  
      return (
        sessionId !== null &&
        sessionId !== undefined &&
        sessionId !== 'null' &&
        sessionId !== 'undefined' &&
        sessionId !== '' &&
        sessionId === 'authenticated'
      );
    };
  
    const goLogout = async () => {
        const url = `${Environment.serverUrl}/api/authorization/logout/`

        const options = {
            method: "POST",
            credentials: "include",
            headers: {
                'X-CSRFToken': getCookie('csrftoken')
            }
        }

        return fetch(url, options)
            .then((r) => {
                if (r.status === 403) {
                    throw new Error("Forbidden")
                }

                if (r.status === 400) {
                    throw new Error("Bad request")
                }

                if (r.status === 500) {
                    throw new Error("Server error")
                }

                window.location.replace('/login')
                return r
            })
            .catch(() => {
                return false
            })
    };
  
    const goLogin = () => {
        return window.location.replace(`/login/`)
    };
  
    return {
      isLoggedIn,
      goLogout,
      goLogin,
    };
  }
  
  export default Auth;

// class Auth {
//     constructor(authUrl, cookieName) {
//         this.authUrl = authUrl

//         this.cookieName = cookieName
//             ? cookieName
//             : 'sessionid'

        
//         this.isLoggedIn = this.isLoggedIn.bind(this)
//         this.goLogout = this.goLogout.bind(this)
//         this.goLogin = this.goLogin.bind(this)
//     }

//     isLoggedIn() {
//         const sessionId = getCookie(`${this.cookieName}_status`)

//         return sessionId !== null &&
//             sessionId !== undefined &&
//             sessionId !== 'null' &&
//             sessionId !== 'undefined' &&
//             sessionId !== '' &&
//             sessionId === 'authenticated'
//     }

//     goLogout() {
//         const url = `${this.authUrl}/api/authorization/logout/`

//         const options = {
//             method: "POST",
//             credentials: "include",
//             headers: {
//                 'X-CSRFToken': getCookie('csrftoken')
//             }
//         }

//         return fetch(url, options)
//             .then((r) => {
//                 if (r.status === 403) {
//                   throw new Error("Forbidden")
//                 }

//                 if (r.status === 400) {
//                   throw new Error("Bad request")
//                 }

//                 if (r.status === 500) {
//                   throw new Error("Server error")
//                 }

//                 // this.dispatch(logout());
//                 window.location.replace('/')
//                 return r
//             })
//             .catch(() => {
//                 return false
//             })
//     }

//     goLogin() {
//         return window.location.replace(`/login/`)
//     }
// }

// export const auth = new Auth(Environment.serverUrl, "sessionid");