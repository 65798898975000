import React, { useEffect, useState } from 'react';
import Constants from '../constants';
import Api from '../utils/Api';
import ReCAPTCHA from 'react-google-recaptcha';

import Background from '../components/Background';
import Process2FA from '../process/Process2FA';

const PROCESS_STATES = [
    'REGISTER',
    'ENABLE-2FA',
    'VALIDATE-2FA',
    'COMPLETED'
]

const Register = () => {
    const [data, setData] = useState({
        email: '',
        password: '',
        token: '',
    })

    const [account, setAccount] = useState(false)
    const [processState, setProcessState] = useState(PROCESS_STATES[0])
    const [registerOn, setRegisterOn] = useState(false)
    const [termAndCondintions, setTermsAndConditions] = useState(false)
    const [validations, setValidations] = useState({
        specialCharacter: false,
        capitalLetter: false,
        lowerCase: false,
        minCharacters: false,
        number: false
    })

    useEffect(( ) => {
        passwordValidations()
        let result = Object.values(validations).every(value => value === true);
        setRegisterOn(result && termAndCondintions)
    }, [data.password, termAndCondintions])

    const createAccount = () => {
        return Api.fetch(Constants.BACKEND_APIS.register(), 'POST', data).then(resp => {
            setAccount(resp)
            setProcessState(PROCESS_STATES[1])
        }).catch(error => {
            console.log('error register')
        })
    }

    const passwordValidations = () => {
        const validation = {
            specialCharacter: /[!@#$%^&*()_+{}:;<>,.?~/-]/.test(data.password),
            capitalLetter: /[A-Z]/.test(data.password),
            lowerCase: /[a-z]/.test(data.password),
            minCharacters: data.password.length >= 8,
            number: /\d/.test(data.password)
        }

        setValidations(validation)
    }

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value || '';
        const name = target.name;
        const newData = {...data};
        newData[name] = value;
        setData(newData);
    }

    function onChangeCaptcha(value) {
        const newData = {...data};
        newData['token'] = value;
        setData(newData);
    }
    
    return (
        <>  
            <Background/>
            <Process2FA 
                account={account}
                processState={processState}
                setProcessState={setProcessState}
                redirectURL={'/login'}
                confirm
            />
            <div className='grid grid-cols-1 lg:grid-cols-12 h-screen w-full'>
                <div className='grid-rows-5 h-full charcoal-grey p-8 hidden lg:grid lg:col-span-2'>
                    <div className='text-white text-center text-1xl mt-4 row-1 font-title'>DIGIPAYMENTS</div>
                    <div className='row-2'></div>
                    <div className='row-2'></div>
                    <div className='row-2'></div>
                    <div className='row-2'></div>
                    <div className='row-2 text-1xl text-white'>Realiza transferencias a cualquier CBU o CVU de tus amigos.</div>
                    {/* <div className='color-yellow-orange text-sm row-1'>Pagos instantáneos y sin comisiones dentro de Digipayments.</div> */}
                </div>
                <div className='col-span-2 flex-col m-5 items-center hidden lg:flex'></div>
                <div className='col-span-3 flex-col m-5 flex items-center mt-16 lg:mt-16'>
                    <div className='text-white text-3xl mb-8'>Es hora de empezar</div>
                    <div className='text-white text-sm mb-2'>Con tan sólo algunos datos puedes cambiar el futuro de tus finanzas.</div>      
                    <div className="mb-6 mt-4 w-full ml-auto mr-auto">
                        <input value={data?.email} onChange={handleChange} name="email" type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tu correo electrónico" required />
                    </div>
                    <div className="mb-6 w-full ml-auto mr-auto">
                        <input onChange={handleChange} value={data?.password} name="password" type="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Crea tu password" required />
                    </div>
                    <div className='grid grid-cols-2 w-full items-start justify-center pl-4 pr-4' >
                        <div className='col-span-1 flex flex-col justify-center'>
                            <div className="flex items-center mb-4">
                                <input checked={validations.specialCharacter} id="default-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-300">Carácter especial</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input checked={validations.capitalLetter} id="default-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-300">Mayúscula</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input checked={validations.lowerCase} id="default-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-300">Minúscula</label>
                            </div>
                        </div>
                        <div className='col-span-1 flex flex-col justify-center'>
                            <div className="flex items-center mb-4">
                                <input checked={validations.minCharacters} id="default-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-300">+ 8 Carácteres</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input checked={validations.number} id="default-checkbox" type="checkbox" value="" className="cursor-pointer w-4 h-4 check-box rounded-xl"/>
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-300">Número</label>
                            </div>
                        </div>
                    
                    </div>
                    <div className="flex items-centr w-full ml-8 mt-4">
                        <input id="link-checkbox" type="checkbox" onChange={() => setTermsAndConditions(!termAndCondintions)} value={termAndCondintions} className=" cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                        <label htmlFor="link-checkbox" className="ml-2 text-xs font-medium text-gray-300">Acepto <a target='_blank' href="/terms-and-conditions" className="text-blue-600 dark:text-blue-500 hover:underline">Términos y condiciones</a> y <a target='_blank' href="/privacy-policy" className="text-blue-600 dark:text-blue-500 hover:underline">Política de Privacidad </a>.</label>
                    </div>
                    
                    <div className='text-white mt-8'>
                        <ReCAPTCHA 
                            sitekey={Constants.CAPTCHA_SITE_KEY} 
                            onChange={onChangeCaptcha}
                        />
                    </div>
                    <button disabled={!registerOn} onClick={createAccount} className="flex w-11/12 ml-auto mr-auto text-base mt-8 mb-8 py-2 px-16 justify-center items-center rounded-full button-action">CREAR CUENTA</button>
                </div>
            </div>
        </>
    )
}

export default Register;