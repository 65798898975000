import React, { useState, useEffect } from 'react';
import Constants from '../constants';
import Api from '../utils/Api';
import Swal from 'sweetalert2'
import Modal from '../utils/Modal';

const PROCESS_STATES = [
    'CREATE',
    'CONFIRM',
    'COMPLETED'
]

const SendTransaction = (props) => {

    const [loading, setLoading] = useState(false)
    const [processState, setProcessState] = useState(PROCESS_STATES[0])
    const [withdrawal, setWithdrawal] = useState({
        amount: '',
        receiver_cvu: '',
        account_name: '',
        detail: '',
    })

    const createWithdrawal = () => {
        setProcessState(PROCESS_STATES[0])
        setLoading(true)
        props.toggleModal()
        Api.fetch(Constants.BACKEND_APIS.createWithdrawal(), 'POST', withdrawal).then(resp => {
            setLoading(false)
            Swal.fire({
                background: 'rgb(38, 43, 51)',
                icon: 'success',
                text: 'Enviado!',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                    content: 'text-white'
                },
                buttonsStyling: false,
            })
        }).catch(error => {
            setLoading(false)
            Swal.fire({
                background: 'rgb(38, 43, 51)',
                icon: 'error',
                text: 'Error al enviar!',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full confirm-button',
                    content: 'text-white'
                },
                buttonsStyling: false,
            })
        });
    }

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value || '';
        const name = target.name;
        const newData = {...withdrawal};
        newData[name] = value;
        setWithdrawal(newData);
    }

    const closeModal = () => {
        setWithdrawal({
            amount: '',
            receiver_cvu: '',
            account_name: '',
            detail: '',
        })
        setProcessState(PROCESS_STATES[0])
        props.toggleModal()
    }

    return (
        <>
            <Modal id='modal-send-transaction' title='Enviar Pesos Argentinos' isOpen={props.isOpen} toggleModal={closeModal} footer={'Esta transaccion es operada por Digipayments SAU'}>
                <>
                    {   processState === PROCESS_STATES[0] ?
                        <div className="mb-8 mt-2 w-5/6 ml-auto mr-auto flex flex-row text-center items-center ">
                            <i className='text-white bx bxs-bank bx-lg ml-1' ></i>
                            <div className='text-white ml-2'>Banco o billetera digital</div>
                        </div>
                        :
                        <div className="mb-8 mt-2 w-5/6 ml-auto mr-auto flex flex-row text-center items-center ">
                            <div className='text-white'>Confirmar datos:</div>
                        </div>
                    }
                    <div className="relative z-0 w-5/6 mb-8 ml-auto mr-auto">
                        <input disabled={processState === PROCESS_STATES[1]} onChange={handleChange} value={withdrawal?.account_name} name="account_name" id="account_name" className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="account_name" className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Beneficiario</label>
                    </div>
                    <div className="relative z-0 w-5/6 mb-8 ml-auto mr-auto">
                        <input disabled={processState === PROCESS_STATES[1]} onChange={handleChange} value={withdrawal?.receiver_cvu} name="receiver_cvu" id="receiver_cvu" className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="receiver_cvu" className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">CBU/CVU</label>
                    </div>
                    <div className="relative z-0 w-5/6 mb-8 ml-auto mr-auto">
                        <input disabled={processState === PROCESS_STATES[1]} onChange={handleChange} value={withdrawal?.amount} name="amount" id="amount" className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="amount" className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Monto a enviar</label>
                    </div>
                    <div className="relative z-0 w-5/6 mb-12 ml-auto mr-auto">
                        <input disabled={processState === PROCESS_STATES[1]} onChange={handleChange} value={withdrawal?.detail} name="detail" id="detail" className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder=" " required />
                        <label htmlFor="detail" className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Descripcion</label>
                    </div>
                    {processState === PROCESS_STATES[0] ?
                        <button onClick={() => setProcessState(PROCESS_STATES[1])} className="flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full button-action">Siguiente</button> 
                        :
                        <>
                        <div className='flex flex-row items-center justify-center'>
                            <button onClick={() => setProcessState(PROCESS_STATES[0])} className="flex py-2 p-8 mb-8 rounded-full button-action-off mr-4 w-32 justify-center">Anterior</button>
                            <button onClick={createWithdrawal} className="flex py-2 p-8 mb-8 rounded-full button-action w-32 justify-center">Enviar</button> 
                        </div>
                        </>
                    }
                </>
            </Modal>
        </>
    )
}

export default SendTransaction;