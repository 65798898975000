import React from 'react';

const Background = () => {
    const totalElements = 25;

    const elements = Array.from({ length: totalElements }, (_, index) => (
        <li key={index}></li>
    ));

    return (
        <ul className="background">
            {elements}
        </ul>
    )
}

export default Background;