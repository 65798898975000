import React from 'react';

const Card = (props) => {
    return (
        <div className={`${props.className}`}>
            {props.label && <div className='text-white'>{props.label}</div>}
                <div className='text-white card-color shadow-xl rounded-xl mt-4 w-full border border-gray-700'>
                    <div className="container mx-auto">
                        {props.children}
                    </div>
                </div>
        </div>
    )
}

export default Card;