import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Constants from '../constants';
import Api from '../utils/Api';
import Modal from '../utils/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../redux/userSlice';

const PROCESS_STATES = [
    'REGISTER',
    'ENABLE-2FA',
    'VALIDATE-2FA',
    'COMPLETED'
]

const Process2FA = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [showSecret, setShowSecret] = useState(false)
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [generated2FA, setGenerated2FA] = useState({
        qr: '',
        secret: '',
    });

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 13) {
                const button = document.getElementById("confirm2FA");
                if (button) {
                    button.click(); 
                }
            }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };

    }, []); 

    const enable2FA = () => {
        return Api.fetch(Constants.BACKEND_APIS.enable2FA(props.account.id), 'GET').then(resp2fa => {
            setGenerated2FA(resp2fa)
            props.setProcessState(PROCESS_STATES[1])
        }).catch(error => {
            console.log('error 2fa')
        })
    }

    const confirm2FA = () => {
        let url = Constants.BACKEND_APIS.validate2FA(props.account.id)
        if (props.confirm) {
            url = Constants.BACKEND_APIS.confirm2FA(props.account.id)
        }
        Api.fetch(url, 'POST', {code: otp}).then(resp => {
            props.setProcessState(PROCESS_STATES[3])
        }).catch(error => {     
            error.json().then(e => {
                setError(e[0])
            })
        })
    }

    const copyToClipboard = text => {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const redirect = (url) => {
        if (props.dispatch) {
            dispatch(login(props.account));
        }
        navigate(url)
    }

    return (
        <Modal noClose id='modal-enable-2fa' title='Debes habilitar la autenticacion 2FA' isOpen={props.processState !== PROCESS_STATES[0] ? true : false} toggleModal={true} footer={'Digipayments Argentina'}>
            { props.processState === PROCESS_STATES[1] &&
                <>
                    <div className='text-white mb-8 text-base'>
                        Es necesario activar la autenticacion en dos pasos para activar tu cuenta.
                        <div></div>
                        <br/>
                        <p className='text-xs'>1- Descarga la Google-Authenticator (Android, iOS)</p>
                        <p className='text-xs'>2- Genera y escanea el siguiente codigo QR.</p>
                        <p className='text-xs'>3- Ingresa el codigo de verificación en la siguiente pantalla.</p>                            
                    </div>
                    { generated2FA.qr === ''
                        ? <button onClick={enable2FA} className="flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full button-action">Generar QR</button>
                        : <img className='w-1/2 ml-auto mr-auto mb-2' src={`data:image/png;base64,${generated2FA.qr}`} alt="Código QR" />
                    }
                    { generated2FA.secret !== '' &&
                        <p className='text-xs text-white items-center italic w-5/6 ml-auto mr-auto text-center mb-4'>Si no puedes escanear el código QR, ingresa la siguiente clave en la aplicación manualmente.
                            <a className='ml-1 text-blue-500 text-sm' onClick={() => setShowSecret(!showSecret)}>Ver clave</a>     
                        </p>
                    } 
                    { showSecret &&
                        <p onClick={() => copyToClipboard(generated2FA.secret)} className='items-center text-white text-sm ml-auto mr-auto text-center mb-4 cursor-pointer'>{generated2FA.secret}</p>
                    }
                    { generated2FA.secret !== '' &&
                        <button onClick={() => props.setProcessState(PROCESS_STATES[2])} className="flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full button-action">Siguiente</button>
                    }
                </>
            }
            { props.processState === PROCESS_STATES[2] &&
                <>
                    <div className='text-white text-sm'>Ingresa el codigo generado por tu aplicación de autenticación</div>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                            padding: "30px 30px 20px 30px",
                            display: "flex",
                            justifyContent: "space-around",

                        }}
                        inputStyle={{
                            border: "1px solid transparent",
                            borderRadius: "8px",
                            width: "3rem",
                            height: "3rem",
                            fontSize: "30px",
                            color: "#fff",
                            background: "rgb(75, 85, 99)",
                            fontWeight: "400",
                            caretColor: "blue",
                        }}
                    />
                    { error &&
                        <div className='flex text-red-600 text-sm mb-4 justify-center'>{error}</div>
                    }
                    <div className='flex flex-row items-center justify-center'>
                        { !props.noReturn &&
                            <button onClick={() => props.setProcessState(PROCESS_STATES[1])} className="flex py-2 p-8 mb-8 rounded-full button-action-off mr-4">Anterior</button>
                        }
                        <button id='confirm2FA' onClick={confirm2FA} className="flex py-2 p-8 mb-8 rounded-full button-action">Validar</button>
                    </div>
                </>
            }
            { props.processState === PROCESS_STATES[3] &&
                <>
                    <div className='flex'>
                        <i className='bx bx-check-circle bx-tada text-8xl ml-auto mr-auto text-green-600' ></i>
                    </div>
                    <div className='flex justify-center text-white mb-8'>Confirmado!</div>
                    <button onClick={() => redirect(props.redirectURL)} className="flex ml-auto mr-auto py-2 p-8 mb-8 rounded-full button-action">Ingresar</button>
                </>
            }
        </Modal>
    )
}

export default Process2FA;